footer {
  background: #000;
  color: #fff;
  padding: 55px 0;
  margin-top: -1px;
}
.footer-logo h1 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  font-style: normal;
  text-align: center;
}
.footer-logo span {
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  display: block;
  font-weight: 200;
  font-style: italic;
}
