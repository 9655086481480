.price {
  background-color: #fff;
}
.price .page {
  padding-top: 15vh;
  padding-bottom: 0;
}
.price-section {
  padding-top: 4vw;
  padding-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
}
.price .page h1 {
  font-weight: 500;
  font-size: 12vw;
  line-height: 12vw;
  position: relative;
  display: inline;
  z-index: 9;
}
.price-hero-img {
  margin-top: -5vw;
}

.price .page h1 .price-subtitle {
  font-weight: 300;
  font-style: italic;
  margin-left: -3vw;
  font-size: 10vw;
}
.price-section-subtitle {
  font-size: 3vw;
  font-style: italic;
  margin-top: 2vh;
  font-weight: 300;
  text-align: center;
}
.price-section-video {
  margin-bottom: 6vw;
}
.price-section-title {
  font-size: 16vw;
  line-height: 100%;
  font-style: italic;
  font-weight: 100;
  text-align: center;
}
.price-item {
  display: flex;
  padding-top: 7vh;
}
.price-item-img {
  max-height: 100vh;
  overflow: hidden;
  flex: 0 0 50%;
}
.price-item-text,
.price-item-img {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.price-item-title {
  font-size: 7vw;
  display: flex;
  position: relative;
}
.price-item-title span {
  font-size: 15vw;
  line-height: 13vh;
  position: absolute;
  font-style: italic;
  font-weight: 100;
  opacity: 0.15;
  top: -4vh;
  right: 2vw;
}
/* .price-item div:nth-child(1) {
  margin-right: 2vw;
} */
.price-item:nth-child(1n) .price-item-text {
  padding-left: 3vw;
}
.price-item:nth-child(2n) .price-item-text {
  padding-right: 3vw;
}
.price-item-list li {
  font-size: 2vw;
}
.price-item-list li:not(.price-item-list-title) {
  padding-left: 30px;
  position: relative;
}
.price-item-list li:not(.price-item-list-title):before {
  content: "•";
  position: absolute;
  left: 7px;
  top: 2px;
}
.price-item-list-title {
  font-weight: 600;
  font-size: 2vw;
  margin-top: 20px;
}
.price-item-number {
  font-size: 6vw;
  font-weight: 300;
  padding-left: 5vw;
}
.price-footer {
  background: #000;
  margin-top: 10vh;
  color: #fff;
}
.price-footer-title {
  text-align: center;
  font-size: 5vw;
  line-height: 5vw;
  position: relative;
  z-index: 1;
}
.price-footer-title .italic {
  padding-left: 0;
}
.price-footer-title span {
  padding: 0 2vw;
}
.price-footer-img {
  max-width: 70vw;
  overflow: hidden;
  max-height: 100vh;
  margin: -4vw auto 2vw;
  z-index: 0;
}
.bd-r {
  position: relative;
}
.bd-r:after {
  content: "";
  background: #fff;
  position: absolute;
  right: 0;
  top: 54%;
  height: 4vw;
  width: 1px;
  transform: translateY(-50%) rotate(15deg);
  margin-left: 2vw;
}
.price-footer-title .bd-r:nth-child(1)::after {
  transform: translateY(-50%) rotate(25deg);
}
.price-section .footer-text-sm:nth-child(2) {
  padding-top: 1vh;
  padding-bottom: 5vh;
}
.price-section .footer-text-sm:nth-child(1) {
  padding-top: 12vh;
  padding-bottom: 7vh;
}
@media (min-width: 1600px) {
  .about-hero-wrap h1 {
    font-size: 102px;
    margin-top: -7vh;
    height: 80%;
  }
}
@media (max-width: 1024px) {
  .price-item {
    flex-direction: column;
  }
  .price-list .price-item:nth-child(odd) {
    flex-direction: column-reverse;
  }
  .price-list .price-item:nth-child(1) .footer-text {
    padding-top: 10px;
  }
  .price-section {
    padding-top: 50px;
  }
  .price .page h1 {
    font-size: 14vw;
  }
  .price-section-title {
    font-size: 22vw;
  }
  .price-item-title {
    font-size: 11vw;
  }
  .price-item-list li {
    font-size: 5vw;
  }
  .price-item-list-title {
    padding-top: 15px;
    padding-bottom: 8px;
  }
  .price-item-number {
    font-size: 12vw;
    text-align: center;
    margin-bottom: 12px;
  }
  .price-section-subtitle {
    font-size: 20px;
  }
  .price-item-title span {
    top: -5vh;
    right: 22vw;
  }
  .price-footer {
    margin-top: 30px;
  }
  .price-footer-title {
    font-size: 25px;
    line-height: 27px;
  }
  .price-section .footer-text-sm:nth-child(1) {
    padding-top: 50px;
  }
  .price-item {
    padding-top: 3vh;
  }
  .price-item-text {
    padding-left: 6vw;
  }
  .price-item:nth-child(1n) .price-item-text {
    padding-left: 0;
  }
}
