#eapps-instagram-feed-1
  .eui-widget-title.es-widget-title.eapps-instagram-feed-title,
#eapps-instagram-feed-1 .eapps-widget-toolbar {
  display: none !important;
}
.eapps-instagram-feed-posts-grid-load-more-text.eapps-instagram-feed-posts-grid-load-more-text-visible {
  background: #000;
}
#eapps-instagram-feed-1 a:not(.eapps-instagram-feed-posts-item-link) {
  font-size: 30px !important;
  display: none !important;
}
