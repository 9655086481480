.projects__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.projects__list li {
  height: 43vw;
  position: relative;
  overflow: hidden;
}
.projects__img {
  position: absolute;
  transition: transform 1.2s;
  overflow: hidden;
}
.projects__list li:hover .projects__img {
  transform: scale(1.03);
}
.projects__list li:hover .projects__item-info {
  opacity: 1;
}
.projects__item-info {
  position: absolute;
  bottom: 0;
  padding: 20px;
  color: #fff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), transparent);
  width: 100%;
  opacity: 0;
  transition: 0.5s;
}
.projects__item-info span {
  display: block;
}
.projects__item-title {
  font-weight: bold;
}
.project-details {
  background: #fff;
}
.project-details__text-container {
  display: flex;
  position: relative;
}
.project-details__slider-container {
  position: relative;
  width: 100%;
}
.project-details__img {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  width: 100%;
  position: relative;
}

.project-details__img-item {
  height: 42vw;
  position: relative;
  overflow: hidden;
}
.project-details__img-item img {
  overflow: hidden;
}
.project-details__img-wrap {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 50%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.project-details__img-wrap--slider {
  width: 100%;
  height: 100vh;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.project-details__text {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 0 10px;
}
.project-details__text-wrap {
  width: 50%;
}
.project-details__location {
  font-size: 25px;
  padding-bottom: 15px;
  position: relative;
}
.project-details__location:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background: #000;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
}
.project-details__title {
  font-size: 75px;
  text-align: center;
  font-style: italic;
}
.project__details-next-link {
  font-size: 50px;
  margin-left: 120px;
  white-space: nowrap;
  font-weight: bold;
}

.mouse {
  width: 50px;
  height: 90px;
  border: 3px solid #fff;
  border-radius: 60px;
  position: absolute;
  bottom: 50px;
  transform: translateX(-50%);
  left: 50%;
  mix-blend-mode: difference;
  cursor: pointer;
  z-index: 99;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
  }
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 60px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 60px;
  }
}

.back {
  color: #fff;
  position: fixed;
  left: 30px;
  top: 30px;
  font-size: 30px;
  cursor: pointer;
  mix-blend-mode: difference;
}

.touch-icon-mob.swipe {
  bottom: 39px;
  font-size: 15px;
}
.touch-icon-mob {
  position: absolute;
  color: #fff;

  right: 30px;
  bottom: 33px;
  font-size: 25px;
  cursor: pointer;
  mix-blend-mode: difference;
  z-index: 1;
}

@media (max-width: 1200px) {
  .project-details__title {
    font-size: 60px;
  }
}
@media (max-width: 1024px) {
  .project-details__text-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    z-index: 9;
  }
  .project-details__img-wrap {
    width: 100%;
  }
  .project-details__text {
    background: transparent;
    color: #fff;
  }
  .project-details__title {
    font-size: 40px;
    font-weight: bold;
    mix-blend-mode: difference;
    color: #fff;
  }
  .project-details__location:after {
    background-color: #fff;
  }
  .projects__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .projects__list li {
    height: 64vw;
  }
}
