.popup-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  bottom: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  padding: 20px;
  text-align: center;
  line-height: 35px;
  font-size: 25px;
}
