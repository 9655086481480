.masonry-wrap {
  background: #fff;
}
@media (max-width: 1024px) {
  .masonry-wrap > div {
    gap: 0 !important;
  }
  .masonry-item img {
    margin-bottom: -4px;
  }
}

/* //////////////// */
.lazy-load-image-background.blur.lazy-load-image-loaded {
  display: flex !important;
}
