.footer-img-list {
  display: flex;
  padding: 18vh 0vh 2vh 0vh;
  background: #000;
}
.footer-img {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1vw;
}
.footer-img img {
  width: 160%;
}
.footer-img.footer-img-1 {
  padding-left: 0;
}
.footer-img-3,
.footer-img-5 {
  margin-top: 6vh;
}
.footer-img:nth-child(2n) {
  margin-top: -18vh;
}
.footer-img-list-wrap {
  overflow: hidden;
}

@media (max-width: 1024px) {
  .footer-img:nth-child(2n) {
    margin-top: -50px;
  }
  .footer-img-list {
    padding-top: 0;
  }
}
