.form-wrapper {
  width: 100%;
}
.form-wrapper h2 {
  font-weight: 500;
  line-height: 34px;
}
.form input {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent !important;
  border: none;
  color: #000;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  outline: none;
  padding: 0 0 16px;
  width: 100%;
  font-family: "Silk Serif", sans-serif;
}
.form .input-row {
  display: flex;
  margin-top: 48px;
  gap: 50px;
}
.form .input-wrap {
  border-bottom: 1px solid #7c7c7c;
  width: 100%;
  position: relative;
}
.form .error-label {
  position: absolute;
  bottom: -30px;
  left: 20px;
  color: #e31a1a;
  display: none;
}
.form .input-wrap.error .error-label {
  display: block;
}
.form .error-label img {
  width: 18px;
  position: absolute;
  left: -20px;
}
.btn-form {
  margin-top: 80px;
  width: 200px;
}
.input-wrap.error {
  border-bottom: 1px solid #e31a1a;
}
@media (min-width: 1024px) {
  .form {
    max-width: calc(50% + 250px);
    min-width: 565px;
  }
}
@media (max-width: 1024px) {
  .form .input-row {
    flex-direction: column;
  }
}
