.privacy {
  padding: 30px;
  background: #fff;
}
.privacy-section {
  padding-top: 150px;
}
.page-h1 {
  border-bottom: 1px solid #7c7c7c;
  margin-bottom: 5vw;
  padding-bottom: 5vw;
}
.page-h1 h1 {
  font-size: 10vw;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.page-h1 h1 span {
  display: block !important;
}
.page-h4 h4 {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.page-p {
  overflow: hidden;
}
.page-p p {
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 30px;
  opacity: 0;
  transform: translateY(-30px);
}
@media (max-width: 1024px) {
  .privacy-section {
    padding-top: 125px;
  }
}
@media (min-width: 1500px) {
  .page-h1 h1 {
    font-size: 170px;
  }
}
