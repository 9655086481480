.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent);
  bottom: 100px;
}

.parallax {
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 40px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
  margin-right: 30px;
}
.projects .parallaxText {
  padding: 50px 0 50px;
}
section {
  position: relative;
  padding: 100px 0 50px;
  background: #fff;
}
@media (max-width: 1024px) {
  #projects-details section {
    padding-top: 50px;
  }

  .projects .parallaxText {
    padding: 30px 0 30px;
  }
  .parallaxText .scroller {
    font-size: 30px;
  }
}
