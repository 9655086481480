.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 999999;
  transform-origin: right;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 15px;
}
.logo-loader {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  mix-blend-mode: difference;
  overflow: hidden;
}
.logo-loader h1 {
  font-size: 28px;
  line-height: 30px;
  font-weight: bold;
}
.logo-loader .subtitle {
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  display: block;
  font-weight: 200;
  font-style: italic;
}
.logo-finish {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 60%;
  font-weight: bold;
  font-size: 40px;
  width: 100%;
  text-align: center;
  opacity: 0;
}
.logo-finish.anim-logo-finish {
  top: 50%;
  opacity: 1;
  transition: 0.3s;
}
.logo-finish-wrap {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  color: #fff;
  width: 100%;
  height: 50px;
}
/* .logo-loader .subtitle {
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  display: block;
  font-weight: 400;
} */
.loader-img img {
  width: auto;
  height: 100dvh;
  max-width: 100vh;
  max-height: 100%;
}

.hidden-anim .loader {
  transition: 0.3s;
  transform: translateX(100%);
}

.hidden-anim-img {
  transform: translateY(-100%);
  transition: 0.5s;
}
.hidden-logo-loader {
  opacity: 0;
  transition: 0.2s;
}

/* //////////// */
/* .loader-img {
  position: absolute;
  max-width: 50%;
  display: none;
}
.loader-img.active {
  display: block;
} */
