.playerWrapper {
  position: relative;
  padding-top: 56.25%;
  max-height: 100vh;
}

.player {
  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;
}

.btnSs {
  max-width: 361px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10;
}

@media (max-width: 768px) {
  .btnSmall {
    width: 150px !important;
    font-size: 13px !important;
    padding: 4px 10px !important;
    gap: 0px !important;
  }
}

@media (max-width: 451px) {
  .playerWrapper {
    margin-bottom: 0px;
  }
}
