.contact {
  background-color: #fff;
}
.page {
  padding: 30px;
}
.contact-hero {
  padding-top: 150px;
}
.contact-title {
  border-bottom: 1px solid #7c7c7c;
  padding-bottom: 5vw;
  margin-bottom: 5vw;
}
.contact-title h1 {
  text-transform: uppercase;
  font-size: 11vw;
  line-height: 100%;
  font-weight: bold;
}
.contact-title-links {
  text-align: right;
}
.contact-title-links img {
  width: 30px;
}
.contact-social-links {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.contact-title-links a {
  display: block;
  font-size: 24px;
  font-weight: bolder;
  text-decoration: underline;
  padding-bottom: 10px;
  text-transform: uppercase;
  transition: 0.4s;
}
.contact-title-links a:hover {
  text-decoration: none;
}
.contact-hero,
.contact-content {
  display: flex;
  justify-content: space-between;
}
.contact-content {
  align-items: end;
}
@media (max-width: 1024px) {
  .contact-hero,
  .contact-content {
    flex-direction: column;
  }
  .contact-content {
    align-items: start;
  }
  .contact-title-links {
    text-align: left;
    margin-top: 50px;
  }
  .contact-social-links {
    justify-content: start;
  }
  .contact-title {
    margin-bottom: 25px;
  }
  .contact-title-links a {
    font-size: 16px;
  }
  .contact-hero {
    padding-top: 125px;
  }
}
@media (min-width: 1500px) {
  .contact-title h1 {
    font-size: 185px;
  }
}
