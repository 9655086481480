.cookie-bar {
  position: fixed;
  bottom: 15px;
  left: 15px;
  background: #fff;
  z-index: 99;
  color: #000;
  width: 300px;
  padding: 20px;
}

#cookie-bar-link {
  display: block;
  margin: 15px 0 25px;
  color: var(--accent-color);
  text-decoration: underline;
}
#cookie-bar-link:hover {
  text-decoration: none;
}
.cookie-text {
  line-height: 1.5;
  opacity: 0.5;
}
#cookie-bar-btn {
  background: #fff;
  border: 2px solid #fff;
  cursor: pointer;
  margin-top: 20px;
  opacity: 0.5;
  border-bottom: 1px solid;
  padding: 0;
}
#cookie-bar-btn:hover {
  opacity: 1;
  border-bottom-color: transparent;
}
