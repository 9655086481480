.reviews {
  background: #fff;
}
.reviews-hero {
  padding-top: 20vh;
}
.reviews-hero-col {
  display: flex;
}
.reviews-hero-img-1 {
  z-index: 2;
  width: 50vw;
}
.reviews-hero-img-2 {
  width: 29vw;
  margin-left: -5vw;
  margin-top: -5vh;
  z-index: 1;
}
.reviews-hero-img-3 {
  width: 30vw;
  margin-left: -1vw;
  z-index: 3;
}
.reviews-hero-img-4 {
  min-width: 25vw;
  margin-top: -3vw;
}
.reviews-hero-img-5 {
  min-width: 30vw;
  margin-left: -4vw;
  z-index: 1;
}
.reviews-hero-img-6 {
  min-width: 27vw;
  margin-top: -15vw;
  margin-left: -7vw;
}
.reviews-hero-img-7 {
  min-width: 36vw;
  z-index: 1;
  margin-top: -13vw;
  margin-left: -6vw;
}
.reviews-hero-col:nth-child(1) {
  padding-left: 1vw;
  padding-right: 1.2vw;
}
.reviews-hero-col:nth-child(2) {
  margin-top: -24vw;
  z-index: 2;
  position: relative;
}

/* CONTENT */

.reviews-section-title {
  font-size: 16vw;
  line-height: 100%;
  font-style: italic;
  font-weight: 100;
  text-align: center;
  margin-top: -10vw;
  position: relative;
  z-index: 2;
}
.reviews-item-text {
  background: rgba(218, 218, 218, 1);
  border-radius: 24px;
}
.reviews-item-text-right {
  text-align: right;
}

.reviews-user-img {
  width: 72px;
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 12px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
}
.reviews-stars-img {
  width: 100px;
  margin-bottom: 24px;
  margin-top: 16px;
}

.reviews-section {
  max-width: 1700px;
  padding-top: 4vw;
  padding-bottom: 80px;
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;
}
.reviews-item-row {
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-template-areas:
    ". ."
    "w-full w-full"
    ". .";
  grid-template-rows: auto;
  align-items: self-start;
  position: relative;
}
.reviews-item {
  display: grid;
  grid-template-columns: auto auto;
}
.w-full {
  grid-area: w-full;
}
.reviews-list {
  padding-top: 3vw;
}

.reviews-item-name {
  font-size: 2vw;
  display: block;
}
.reviews-date {
  font-size: 1.2vw;
}
.reviews-content-title-sm {
  position: absolute;
  top: 20vw;
  right: 0;
  z-index: 1;
}
.reviews-content-title-sm span {
  font-size: 10vw;
}
.reviews-content-title-big {
  position: absolute;
  top: 0;
  z-index: 1;
}
.reviews-content-title-big span {
  font-size: 16vw;
}
.reviews-content-title span {
  display: block;
  transform: rotate(-90deg);
  line-height: 100%;
}
/* REVIEWS ITEM */
.reviews-item-text {
  padding: 3vw;
  z-index: 2;
}
.reviews-item-text p {
  text-transform: uppercase;
  font-size: 1.6vw;
  line-height: 150%;
}
.reviews-item-1 {
  margin-top: 8vw;
  width: 33vw;
  margin-left: auto;
}
.reviews-item-2 {
  margin-left: -70px;
  width: 48vw;
}
.reviews-item-3 {
  width: 55vw;
  margin: 45px auto 30px;
}
.reviews-item-4 {
  width: 35vw;
  margin-left: auto;
}
.reviews-item-5 {
  width: 37vw;
  margin-top: 8vw;
  margin-left: 2vw;
}

@media (max-width: 1024px) {
  .reviews-section-title {
    font-size: 22vw;
  }
  .reviews .footer-img-list {
    padding-top: 8vh;
  }
  .reviews-item-text p {
    font-size: 16px;
  }
  .reviews-item-text {
    padding: 19px;
  }
  .reviews-item-name {
    font-size: 20px;
  }
  .reviews-date {
    font-size: 14px;
  }
  .reviews-stars-img {
    width: 100px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .reviews-item-row {
    display: block;
  }
  .reviews-item-1 {
    margin-top: 20px;
    width: 100%;
    margin-left: 0;
  }
  .reviews-item-2 {
    margin-left: 0;
    width: 100%;
    margin-top: 35px;
  }
  .reviews-item-3 {
    width: 100%;
    margin: 35px 0 0;
  }
  .reviews-item-4 {
    width: 100%;
    margin: 35px 0 0;
  }
  .reviews-item-5 {
    width: 100%;
    margin: 35px 0 0;
  }
  .reviews-user-img {
    width: 60px;
    height: 60px;
  }
  .reviews-content-title-big {
    bottom: 50px;
    top: auto;
  }
}
