.swiper-wrapper {
  background: #000;
  height: 100vh;
}
.slider-count {
  color: #fff;
  position: absolute;
  left: 30px;
  bottom: 30px;
  font-weight: bolder;
  font-size: 30px;
  cursor: pointer;
  mix-blend-mode: difference;
  z-index: 9;
}
.slider-last-img {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  white-space: nowrap;
}
.swiper-slide {
  animation: showNextSlide ease-in-out 3s alternate;
  animation-delay: 3s;
}
.swiper-slide-active,
.swiper-container {
  background: #fff;
}

@keyframes showNextSlide {
  0% {
    width: 100%;
  }
  50% {
    width: 90%;
  }
  100% {
    width: 100%;
  }
}
