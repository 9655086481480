.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 9999999999;
  transform-origin: right;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 15px;
}
.logo-loader {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  mix-blend-mode: difference;
  animation: circleScale 3s infinite;
}
.logo-loader h1 {
  font-size: 28px;
  line-height: 30px;
  font-weight: bold;
}

.logo-loader .subtitle {
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  display: block;
  font-weight: 400;
}

@keyframes circleScale {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}
