.menu {
  display: none;
}
.menu ul {
  display: flex;
}
.menu li {
  margin-left: 30px;
}
.menu li a {
  padding-bottom: 5px;
}
.menu li a:hover {
  border-bottom: 3px solid var(--accent-color);
}
.menu li a.active {
  border-bottom: 3px solid var(--accent-color);
}
.logo.mob {
  color: #000;
}
.logo {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  position: fixed;
  top: 30px;
  left: 50%;
  z-index: 1;
  mix-blend-mode: difference;
  z-index: 99999;
  transform: translateX(-50%);
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.logo h3 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  font-style: normal;
}
.logo span {
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  display: block;
  font-weight: 200;
  font-style: italic;
}
.nav-burger.active {
  top: 30px;
  right: 30px;
}
.nav-burger {
  position: fixed;
  right: 30px;
  top: 30px;
  cursor: pointer;
  z-index: 1;
  mix-blend-mode: difference;
  z-index: 9999;
}
.icon {
  position: relative;

  color: #fff;
  position: fixed;
  right: 30px;
  top: 30px;
  font-weight: bolder;
  font-size: 30px;
  cursor: pointer;
  mix-blend-mode: difference;
  font-weight: 400;
  font-style: normal;
}
.icon div {
  height: 4px;
  background-color: #fff;
  margin-bottom: 6px;
  margin-left: auto;
  transition: 0.4s;
}
.icon div:nth-child(1) {
  width: 44px;
}
.icon div:nth-child(2) {
  width: 37px;
}
.icon div:nth-child(3) {
  width: 30px;
}
.icon div:nth-child(3) {
  margin-bottom: 0;
}
.icon:hover div {
  width: 44px;
}
.nav-burger.active {
  width: 50px;
  height: 50px;
  z-index: 99999;
}
.nav-burger.active .icon div {
  background: #fff;
}
.nav-burger.active .icon div:nth-child(1) {
  transform: rotate(-45deg);
  position: absolute;
  width: 37px;
}
.nav-burger.active .icon div:nth-child(2) {
  display: none;
}
.nav-burger.active .icon div:nth-child(3) {
  transform: rotate(45deg);
  position: absolute;
  width: 37px;
}
.menu-mob li a {
  opacity: 0;
}
.menu-mob {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #000;

  z-index: -1;
  /* opacity: 0; */
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}
.menu-mob.active {
  z-index: 100;
  /* opacity: 1; */
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 9999;
  height: 100%;
}
.menu-mob.active nav {
  transition: 0.3s;
  /* opacity: 1; */
}
.menu-mob nav {
  width: 100%;
  height: 100vh;
  background-color: var(--accent-color);
  display: flex;
  align-items: center;
  transition: 0.3s;
  /* opacity: 0; */
  margin-left: 12vw;
}
.nav-item-item {
  transform: translateX(0);
  transition: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.menu-mob.active li a {
  width: 100%;
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.menu-mob.active li :hover a {
  transform: translateX(-16px);
}
.menu-mob.active li a.active {
  transform: translateX(-16px);
  opacity: 1;
}

.menu-mob.active .social img {
  width: 30px;
  filter: invert(100%);
}
@media (max-width: 1400px) {
  .menu-mob.active li a {
    font-size: 45px;
  }
  .nav-item-wrap {
    padding: 10px 0px 10px 0 !important;
  }
}
@media (max-width: 1024px) {
  .social a {
    font-size: 23px;
  }
  .menu-mob .img-nav {
    display: none;
  }
}

/* /////////////////// */

.object-cover {
  object-fit: cover;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.menu-mob ul {
  width: 100%;
}

.menu-mob li a {
  color: #fff;
  font-size: 55px;
  line-height: 115%;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 0.5;
}
.menu-mob li a:hover {
  opacity: 1;
}
.menu-mob li:hover img.img-nav {
  transform: scale(1) rotate(12.5deg);
}
.menu-mob .img-nav {
  width: 20vw;
  z-index: 9;
  transform: scale(0) rotate(-12.5deg) translate(-50%, -50%);
  position: absolute;

  top: 20%;
  left: 60%;
  transition: 0.3s;
}
.text-white {
  color: #fff;
}
.nav-item-wrap {
  padding: 15px 60px 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 1.5s cubic-bezier(0.16, 1, 0.3, 1);
}
.menu-mob.active .nav-link-wrap.active {
  margin-top: 50px;
}
.inline-block {
  display: inline;
}
.menu-mob.active li a.active {
  transform: translateX(-16px);
  opacity: 1;
}

.social {
  position: absolute;
  bottom: 30px;
  width: 100%;
}
.social-in {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.social a {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  padding: 0 9px;
  top: -100%;
  position: relative;
}
.social .link-wrap {
  overflow: hidden;
  font-size: 30px;
}
.social .link-wrap.active a {
  top: 0;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0.5;
}
.social .link-wrap.active a:hover {
  opacity: 1;
}
.nav-privacy-link a {
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  justify-content: center;
  opacity: 0;
  /* transition: 0.1s; */
  margin-top: 20px;
  /* position: relative;
  top: -100%; */
}
.nav-privacy-link.active a {
  opacity: 0.5;

  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.nav-privacy-link a:hover {
  opacity: 1;
}
.nav-privacy-link {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
