@font-face {
  font-family: "BonaTitleRegular";
  src: url("./fonts/bona-title-regular.otf") format("truetype");
}
@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-BlackItalic.eot");
  src: local("Silk Serif Black Italic"), local("./fonts/SilkSerif-BlackItalic"),
    url("./fonts/SilkSerif-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-BlackItalic.woff2") format("woff2"),
    url("./fonts/SilkSerif-BlackItalic.woff") format("woff"),
    url("./fonts/SilkSerif-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-BoldItalic.eot");
  src: local("Silk Serif Bold Italic"), local("./fonts/SilkSerif-BoldItalic"),
    url("./fonts/SilkSerif-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-BoldItalic.woff2") format("woff2"),
    url("./fonts/SilkSerif-BoldItalic.woff") format("woff"),
    url("./fonts/SilkSerif-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-LightItalic.eot");
  src: local("Silk Serif Light Italic"), local("./fonts/SilkSerif-LightItalic"),
    url("./fonts/SilkSerif-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-LightItalic.woff2") format("woff2"),
    url("./fonts/SilkSerif-LightItalic.woff") format("woff"),
    url("./fonts/SilkSerif-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-Regular.eot");
  src: local("Silk Serif"), local("./fonts/SilkSerif-Regular"),
    url("./fonts/SilkSerif-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-Regular.woff2") format("woff2"),
    url("./fonts/SilkSerif-Regular.woff") format("woff"),
    url("./fonts/SilkSerif-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-ExtraLightItalic.eot");
  src: local("Silk Serif ExtraLight Italic"),
    local("./fonts/SilkSerif-ExtraLightItalic"),
    url("./fonts/SilkSerif-ExtraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/SilkSerif-ExtraLightItalic.woff2") format("woff2"),
    url("./fonts/SilkSerif-ExtraLightItalic.woff") format("woff"),
    url("./fonts/SilkSerif-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-SemiBoldItalic.eot");
  src: local("Silk Serif SemiBold Italic"),
    local("./fonts/SilkSerif-SemiBoldItalic"),
    url("./fonts/SilkSerif-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/SilkSerif-SemiBoldItalic.woff2") format("woff2"),
    url("./fonts/SilkSerif-SemiBoldItalic.woff") format("woff"),
    url("./fonts/SilkSerif-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-SemiBold.eot");
  src: local("Silk Serif SemiBold"), local("./fonts/SilkSerif-SemiBold"),
    url("./fonts/SilkSerif-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-SemiBold.woff2") format("woff2"),
    url("./fonts/SilkSerif-SemiBold.woff") format("woff"),
    url("./fonts/SilkSerif-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-MediumItalic.eot");
  src: local("Silk Serif Medium Italic"),
    local("./fonts/SilkSerif-MediumItalic"),
    url("./fonts/SilkSerif-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-MediumItalic.woff2") format("woff2"),
    url("./fonts/SilkSerif-MediumItalic.woff") format("woff"),
    url("./fonts/SilkSerif-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-ExtraLight.eot");
  src: local("Silk Serif ExtraLight"), local("./fonts/SilkSerif-ExtraLight"),
    url("./fonts/SilkSerif-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-ExtraLight.woff2") format("woff2"),
    url("./fonts/SilkSerif-ExtraLight.woff") format("woff"),
    url("./fonts/SilkSerif-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-Medium.eot");
  src: local("Silk Serif Medium"), local("./fonts/SilkSerif-Medium"),
    url("./fonts/SilkSerif-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-Medium.woff2") format("woff2"),
    url("./fonts/SilkSerif-Medium.woff") format("woff"),
    url("./fonts/SilkSerif-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-Light.eot");
  src: local("Silk Serif Light"), local("./fonts/SilkSerif-Light"),
    url("./fonts/SilkSerif-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-Light.woff2") format("woff2"),
    url("./fonts/SilkSerif-Light.woff") format("woff"),
    url("./fonts/SilkSerif-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-Italic.eot");
  src: local("Silk Serif Italic"), local("./fonts/SilkSerif-Italic"),
    url("./fonts/SilkSerif-Italic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-Italic.woff2") format("woff2"),
    url("./fonts/SilkSerif-Italic.woff") format("woff"),
    url("./fonts/SilkSerif-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-Bold.eot");
  src: local("Silk Serif Bold"), local("./fonts/SilkSerif-Bold"),
    url("./fonts/SilkSerif-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-Bold.woff2") format("woff2"),
    url("./fonts/SilkSerif-Bold.woff") format("woff"),
    url("./fonts/SilkSerif-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Silk Serif";
  src: url("./fonts/SilkSerif-Black.eot");
  src: local("Silk Serif Black"), local("./fonts/SilkSerif-Black"),
    url("./fonts/SilkSerif-Black.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SilkSerif-Black.woff2") format("woff2"),
    url("./fonts/SilkSerif-Black.woff") format("woff"),
    url("./fonts/SilkSerif-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Silk Serif", sans-serif;
  width: 100%;
  height: 100%;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
}
img {
  width: 100%;
}
.mask-img {
  visibility: hidden;
  position: relative;
  overflow: hidden;
}
.italic {
  font-style: italic;
}

.btn {
  align-items: center;
  border: 1px solid #000;
  background: #000;
  cursor: pointer;
  display: flex;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  justify-content: center;
  overflow: hidden;
  padding: 14px 24px;
  position: relative;
  text-transform: uppercase;
  transition: all 0.3s ease;
  white-space: nowrap;
  font-family: "Silk Serif", sans-serif;
}
.btn:hover {
  background: #fff;
  color: #000;
}
.line {
  overflow: hidden;
}
.line .word {
  transform: translateY(115px);
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); */
}
.footer-text-section {
  text-align: center;
}
.footer-text {
  font-size: 2vw;
  line-height: 2.5vw;
}
.footer-text-sm {
  font-size: 1.2vw;
  line-height: 1.7vw;
}

.page-transition__black {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: #000;
}
.page-transition__red {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: red;
}

@keyframes heightTopToBottom {
  0% {
    transform: translateY(0);
    background: #000;
  }

  100% {
    transform: translateY(100%);
    background: transparent;
  }
}
@keyframes moveInleft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@media (max-width: 1024px) {
  /* body,
  html {
    height: 100dvh;
    max-height: 100%;
  } */
  .rotate-mob__left {
    transform: rotate(-90deg);
    top: 60px !important;
    left: 0px !important;
    font-size: 25px !important;
    z-index: 99;
  }
  .rotate-mob__right {
    transform: rotate(90deg);
    top: 60px !important;
    right: 0px !important;
    font-size: 25px !important;
  }
  .btn {
    font-size: 16px;
  }
  .footer-text {
    font-size: 19px;
    line-height: 22px;
  }
  .footer-text-sm {
    font-size: 14px;
    line-height: 20px;
  }
}
