.about {
  background-color: #000;
}
.page {
  padding: 30px;
}
.about .page {
  max-width: 1500px;
  margin: auto;
  padding-top: 5vw;
}
.about-hero {
  background-color: #000;
  color: #fff;
  min-height: 85vh;
}
.about-hero-wrap {
  display: flex;
  padding-top: 12vh;
}
.about .page {
  position: relative;
}
.about-hero-title,
.about-hero-img {
  display: flex;
  width: 100%;
}
.about-hero-title span {
  font-style: italic;
  font-weight: 300;
  padding-right: 1vw;
}
.about-hero-img {
  max-width: 80%;
  padding-top: 0vh;
  margin-bottom: -13vh;
  z-index: 1;
}
.about-hero-wrap h1 {
  font-size: 6vw;
  line-height: 100%;
  transform: rotate(-90deg);
  height: 50%;
  margin-top: 2vw;
}
/* CONTENT */
.about-content {
  background-color: #fff;
  padding-bottom: 10vh;
}
.section-title h2 {
  font-size: 11vw;
  font-style: italic;
  font-weight: 100;
}
.about-title {
  text-align: right;
  margin-right: -10px;
  margin-right: calc(50% - 10px);
  position: relative;
  z-index: 1;
}
.about-content-top {
  margin-top: -10vh;
}
.about-content-top,
.about-content-bottom {
  display: flex;
}
.about-content-top-img,
.about-content-top-text,
.about-content-bottom-img,
.about-content-bottom-text {
  display: flex;
  width: 100%;
}
.about-content-bottom-text {
  margin-left: 8vw;
}
.about-content-bottom-text .about-text {
  margin-bottom: 3vh;
}
.about-content-top-text {
  flex-direction: column;
  padding-right: 30px;
  margin-left: 8vw;
}
.about-content-top-img {
  margin-top: -10vh;
  margin-right: -8vw;
  align-items: start;
  flex-direction: column;
}
.about-content-top-text h5 {
  text-align: right;
  font-size: 2.3vw;
  padding-bottom: 2vh;
  font-weight: 600;
  line-height: 135%;
  padding-left: 20px;
}

.about-content-top-text li {
  text-transform: uppercase;
  font-size: 1.6vw;
  line-height: 150%;
  text-align: right;
}
.about-text {
  text-transform: uppercase;
  font-size: 1.6vw;
  line-height: 150%;
}
.about-content-bottom-img {
  padding-left: 15vw;
  margin-left: auto;
  margin-top: 6vh;
  position: relative;
}
.about-text-in {
  padding-left: 7vw;
  padding-top: 6vh;
}
.about-img-text-top {
  position: absolute;
  top: 15px;
  right: 16px;
  color: #fff;
}
.about-img-text-top span {
  font-size: 10vw;
  line-height: 100%;
  margin-left: 2vw;
  font-weight: bold;
}
.about-img-text-top p {
  font-size: 8vw;
  line-height: 70%;
}
.about-img-text-bot span {
  font-size: 8vw;
  line-height: 100%;
  margin-left: 2vw;
  font-weight: bold;
}
.about-img-text-bot p {
  font-size: 6vw;
  line-height: 70%;
}
.about-img-text-bot {
  position: absolute;
  bottom: 50px;
  left: 16vw;
  color: #fff;
}
.footer-text-top {
  text-transform: uppercase;
  font-size: 2vw;
  color: #fff;
  text-align: center;
  width: 60%;
  margin: auto;
}
.footer-text-bot {
  text-transform: uppercase;
  font-size: 2.4vw;
  color: #fff;
  text-align: center;
  width: 65%;
  margin: auto;
}
.mob {
  display: none;
}
.desc {
  display: block;
}
@media (min-width: 1500px) {
  .about-img-r {
    min-width: 710px;
  }
  .section-title h2 {
    font-size: 190px;
  }
}
@media (max-width: 1024px) {
  .about-content-top {
    flex-direction: column;
  }
  .about-content-top-text {
    margin-left: 0;
  }
  .about-hero {
    min-height: 44vh;
  }
  .about-hero-img {
    align-items: center;
    margin-bottom: -150px;
    width: 50%;
    margin-left: auto;
  }
  .about-hero-wrap h1 {
    font-size: 7vw;
    line-height: 125%;
    height: 80%;
    margin-top: 1vh;
  }
  .about-hero-title {
    position: absolute;
    left: 0px;
  }
  .about-hero-wrap {
    padding-top: 14vh;
  }
  .mob {
    display: block;
  }
  .desc {
    display: none;
  }
  .vis-hid {
    visibility: hidden;
  }
  .about-content-top-img {
    margin-top: 3vh;
  }
  .about-content-top-img .mob {
    padding: 0 30px 40px;
  }
  .about-content-top-img h5 {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 15px;
    font-weight: 500;
  }
  .about-content-top-img .mob {
    text-align: right;
  }
  .about-content-top-img li {
    font-size: 16px;
    line-height: 27px;
  }

  .section-title.mob {
    width: 100%;
    padding-left: 30px;
    position: absolute;
  }
  .section-title.mob h2 {
    font-size: 85px;
  }
  .about-content-bottom-text {
    margin-left: 0;
  }
  .about-content-bottom-text .about-text {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 15px;
  }
  .about-text-in {
    padding: 30px;
  }
  .about-content-bottom-img {
    margin-top: 0;
  }
  .about-footer .page {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .footer-text-top {
    width: 100%;
    font-size: 16px;
    line-height: 27px;
  }
  .footer-text-bot {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
  }
  .about-img-text-bot span {
    font-size: 44px;
    line-height: 100%;
    margin-left: 30px;
  }
  .about-img-text-bot p {
    font-size: 35px;
    line-height: 90%;
    margin-left: 15px;
  }
  .about-img-text-top span {
    font-size: 55px;
    line-height: 100%;
    margin-left: 15px;
  }
  .about-img-text-top p {
    font-size: 40px;
    line-height: 70%;
  }
}
